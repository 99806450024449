.sideform_container {
  /* transform: scale(0.65);
  transform-origin: top right;  */
  position: fixed;
  right: 20px;
  top: 50px;
  z-index: 500;
  height: 85vh;
  /* max-height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideform_instant {
  position: relative;
  left: 0;
  float: none;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
  text-align: center;
  z-index: 500;
  border-radius: 10px;
  width: 220px;
  height: 283px;
  /* padding-top: 1px; */
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
  /* margin-bottom: 1px; */
}

.sideform_range {
  left: 0;
  float: none;
  position: relative;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
  text-align: center;
  z-index: 500;
  border-radius: 10px;
  width: 220px;
  height: 344px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
}

.sideform_graph {
  left: 0;
  float: none;
  position: relative;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
  text-align: center;
  z-index: 500;
  border-radius: 10px;
  width: 220px;
  height: 312px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
}

.sideform_correlation {
  left: 0;
  float: none;
  position: relative;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
  text-align: center;
  z-index: 500;
  border-radius: 10px;
  width: 230px;
  height: 407px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
}

/* CorrelationTab.css */
.label_dropdown_container {
  display: flex; /* Enables flex layout */
  align-items: center; /* Vertically aligns the labels and dropdown */
}

.label_container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text inside */
  margin-left: 10px; /* Adjust this value if you need the whole label-container shifted */
}

.label_color {
  color: white; /* Adjust to blue or red as needed */
  font-size: 1.0em; /* Adjust font size as needed */
  margin-bottom: 2px; /* Spacing between "(color)" and "Parameter" */
}

.label_text {
  font-size: 1em; /* Adjust font size as needed */
  font-weight: normal; /* Optional: Make it distinct from the label */
}


.sideform_color_ramp {
  position: relative;
  left: 0;
  top: 0;
  float: right;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(67, 68, 64) 0%, rgb(106, 106, 105) 100%);
  text-align: center;
  z-index: 300;
  border-radius: 15px;
  width: 208px;
  height: 100px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
  padding-top: 3px;
  margin-bottom: 12px;
}

.stats_container {
  position: relative;
  left: 0;
  top: 30;
  float: right;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(67, 68, 64) 0%, rgb(106, 106, 105) 100%);
  text-align: center;
  z-index: 400;
  border-radius: 10px;
  width: 208px;
  height: 60px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 28px;
}

.model_stats_container {
  position: relative;
  left: 0;
  top: 0;
  float: right;
  color: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(67, 68, 64) 0%, rgb(165, 166, 161) 100%);
  text-align: left;
  z-index: 400;
  border-radius: 10px;
  width: 208px;
  height: 92px;
  font-size: 11px;
  font-family: Georgia, "Times New Roman", Times, serif;
  opacity: 90%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.model_hour_box {
  width: 30px;
  display: block;
}

.model_hour_box_div {
  margin-left: 60px;
  text-indent: -33px;
  margin-top: 3px;
  text-align: center;
}

.model_current_hour_box {
  width: 30px;
  display: block;
}

.model_run_box .dropdown-toggle {
  width: 145px;
  display: inline-block;
  vertical-align: middle;
}

.model_run_box_div {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 10px;
  margin-left: 23px;

}

.model_run_box_font {
  font-size: 11px;
}

.model_run_default {
  background-color: #0328fc !important;
  font-size: 11px !important;
  width: 118px !important;
}

.model_run_dropdown {
  width: auto;
  /* This makes the width content-based */
  min-width: 0 !important;
  /* Override any default minimum width */
}

.label_margin_right {
  margin-right: 50px;
  /* Adjust the value as needed */
}

.statsbox {
  width: 50px;
  /* Adjust the width as needed */
  margin-top: 1px;
  text-align: center;
  /* margin-right: 1px; */
}

/* Container for the tab name */
.tabNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Style for the tab name */
.tabName {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-top:12px;
}

/* Container for the "More" dropdown */
.moreDropdownContainer {
  position: absolute;
  top: 0px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
}

.moreDropdown {
  color: white !important;
  background-color: transparent;
  border: none;
  font-weight: bold;
  /* text-align: center; */
  margin-right: -10px;
  font-size: 14px !important;
}
.moreDropdown:hover,
.moreDropdown:focus {
  color: white; /* Keeps the font color white on hover/focus */
  text-decoration: none; /* Prevents underline on hover */

}

/* Adjust the compressed dropdown if needed */
.compressedDropdown {
  width: 70px; /* Adjust as needed */
  padding: 0;
  text-align: center;
}

.sideformTabSelected {
  /* Your desired styles */
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  /* Add other styles as needed */
}

.tabsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.compressedDropdown {
  width: 70px; /* Adjust the width as needed */
  padding: 0; /* Adjust padding if necessary */
  text-align: center;
}

.ramps_stats_container {
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Stacks items vertically */
  align-items: center; /* Centers items horizontally */
  justify-content: flex-start; /* Aligns items to the top, adjust if needed */
  width: 100%; /* Make the container span the full width */
  /* margin-top: 20px;
  gap: 12px; */
}


.dropdown_param {
  color: rgb(255, 255, 255);
  background-color: #0a162f;
  border-radius: 5px;
  padding: 3px;
  margin-left: 10px;
  width: fit-content;
  font-size: 11px;
}

.dropdown_model {
  color: rgb(255, 255, 255);
  background-color: #0a162f;
  border-radius: 5px;
  padding: 3px;
  width: fit-content;
  font-size: 11px;
  margin-left: 10px;
  margin-top: 10px;

}

.submit_button {
  font-size: 11px !important;
  margin-top: -10px !important;
  padding: 3px 6px !important;
}

.utc_toggle {
  margin-left: 8px !important;
  background: transparent !important;
  font-size: 11px !important;
  border: none !important;
  outline: none !important;
  margin-top: -10px !important;
}

.utc_toggle:active,
.utc_toggle:hover {
  background-color: rgb(36, 95, 231) !important;
}

.small_button {
  font-size: 13px !important;
  padding: 2px 6px !important;
}

.absoluteDatePicker {
  position: absolute;
  top: 50px;
  /* Adjust as needed */
  left: 50px;
  /* Adjust as needed */
  z-index: 1000;
  /* Ensure it's above other content */
}

.datePickerLabelContainer {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  /* Align items to the start of the container */
  margin-bottom: 5px;

}

.myForecastContainer {
  margin-top: 7px;
  text-align: center;
}

.myForecastButton {
  background-color: #040404;
  border: none;
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  opacity: 90%;
}

.myForecastButton:hover {
  background-color: #110263;
}


/* Define common styles for all orientations within the width range */
@media (min-width:0px) and (max-width: 1024px) {
  .sideform_container {
    transform: scale(0.90);
    transform-origin: top right;
  }
}

/* Landscape orientation */
@media (min-width:0px) and (max-width: 1024px) and (orientation: landscape) {
  .sideform_container {
    top: 1px;
    height: 85vh;
  }
}

/* Portrait orientation */
@media (min-width:0px) and (max-width: 640px) and (orientation: portrait) {
  .sideform_container {
    top: 100px;
  }
}


