.social-media-container {
    position: absolute;
    z-index: 400;
    background: transparent;
    display: flex;
    justify-content: space-around; /* This will space out the icons evenly */
    align-items: center;
    width: 230px;
    height: 35px;
    top: 5px;
    left:70px;
}

.youtube {
    height: 25px;
    width: 25px;
    margin-right: 10px
}

.twitter, .facebook {
    height: 20px;
    width: 20px;
}

.social-text {
    text-shadow: 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white;
    color: rgb(21, 52, 255);
    font-weight: bold;
    font-size: 1.2em;
}