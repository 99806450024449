.mapContainer {
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.ad_banner {
  height: 7vh;
  /* Ad banner takes up 5% of the viewport height */
  background-color: #f3f3f3;
  /* Example background color */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}


.titleimage {
  float: left;
  margin-bottom: 5px;
  margin-right: 10px;

  width: 45px;
  height: 45px;

  /* width: 100px; */
  /* filter: sepia(1) saturate(2); */
  /* background-color: black;
    border-radius: 50%;
    padding: 10px; */
}

.titlecontainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.title {
  color: rgb(25, 18, 234);
  position: absolute;
  top: -20px;
  left: 35%;
  z-index: 400;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.sourceSerifPro {
  font-size: 32px !important;
  /* or whatever size you prefer */
  /* other styles */
}

/* @font-face {
  font-family: 'Source Serif 4';
  src: url('./fonts/SourceSerif4-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */
.title h1 {
  font-size: 35px;
  color: blue;
  /* font-family: 'Source Serif Pro', serif; */
  font-family: 'Source Serif 4', serif;
  font-weight: 400;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}


.llcform {
  color: rgb(25, 18, 234);
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 400;
}

.legend {
  position: absolute;
  float: center;
  bottom: 0;
  left: 25%;
  z-index: 401;
  width: 50%;
  height: 40px;
}

.clear_button {
  margin-top: 100px;
  /* Adjust this value to position the button below the other editing tools */
}

.zoom_to_location_button {
  position: absolute;
  top: 244px;
  left: 0px;
  border-radius: 5px;
  border: 1.5px solid grey !important;
}


div.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.darkTiffTooltip {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  /* font-size: 1.5em; */
}

.aboutlink {
  top: 10px;
  z-index: 400;
  position: absolute;
  right: 40px;
  text-shadow: 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white, 0px 0px 3px white;
  color: rgb(21, 52, 255) !important;
  font-weight: bold;
  font-size: 1.2em;
}


.banner {
  position: fixed;
  top: 10px;
  right: 250px;
  width: 200px;
  color: rgb(21, 52, 255) !important;
  font-size: 1.2em;
  /* background-color: rgba(59, 88, 255, 0.5); */
  /* padding: 10px; */
  text-align: center;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
}

.closeButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: 18px;
}

.popupContainer {
  position: fixed;
  left: 0;
  top: 0; /* Ensures full-screen overlay */
  width: 100%; /* Full-screen width */
  height: 100%; /* Full-screen height */
  background-color: rgba(0, 0, 0, 0.5); /* Keep the overlay effect */
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the content */
  z-index: 1000;
}

.popupContent {
  position: relative;
  width: 60%; /* Reduce width to scale down */
  max-height: 70vh; /* Adjust height to make it smaller */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling for overflowing content */
  padding: 20px; /* Add padding for spacing */
  margin: auto; /* Ensure the content stays centered */
}

.closePopupButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 16px;
  z-index: 1001; /* Ensure the close button is always on top */
}

.popupImage {
  width: 85%; /* Adjust the image width to fit the smaller popup */
  height: auto; /* Maintain aspect ratio */
  max-height: 85%; /* Ensure the image doesn't exceed the popup height */
  display: block; /* Center the image within the popupContent */
  margin: 0 auto;
}



@media (min-width:0px) and (max-width: 640px) and (orientation: portrait) {
  .aboutlink {
    top: 10px;
  }

  .title {
    margin-top: 37px;
    left: 120px;
  }

  .titleimage {
    width: 35px;
    height: 35px;
  }

  .sourceSerifPro {
    font-size: 28px !important;
    /* or whatever size you prefer */
    /* other styles */
  }

  .mapContainer {
    height: 87vh;
  }
}

@media (min-width:0px) and (max-width: 1024px) and (orientation: landscape) {
  .aboutlink {
    top: 10px;
    right: 243px;
  }

  .title {
    margin-top: 10px;
  }

  .titleimage {
    width: 35px;
    height: 35px;
  }

  .sourceSerifPro {
    font-size: 28px !important;
    /* or whatever size you prefer */
    /* other styles */
  }

  .mapContainer {
    height: 87vh;
  }
}