.measure_area_button_common  {
    position: fixed;
    border: none;
    border: 1.5px solid grey;
    cursor: pointer;
    z-index: 9999;
    bottom: 173px;
    left: 10px;
    border-radius: 5px;
    width: 33px;
    height: 33px;
  }

  .measure_area_button {
    background-color: white;
    padding: 3px;
  }
  
  .measure_area_button_dark {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 3px;
  }

  .measure_dist_button_common {
    position: fixed;
    border: none;
    border: 1.5px solid grey;
    cursor: pointer;
    z-index: 9999;
    bottom: 208px;
    left: 10px;
    border-radius: 5px;
    width: 33px;
    height: 33px;
  }
  
  .measure_dist_button {
    background-color: white;
    padding: 3px;
  }
  
  .measure_dist_button_dark {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 3px;
  }
  
  .darkTooltip {
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    border-radius: 4px; /* Rounded corners */
    padding: 5px; /* Some padding */
    font-size: 1.5em; /* Larger font size */
  }

  @media (min-width: 375px) and (max-width: 1386px) and (orientation: landscape){
    .measure_area_button_common  {
      bottom: 93px;
    }
  }

  @media (min-width: 375px) and (max-width: 1386px) and (orientation: landscape){
    .measure_dist_button_common  {
      bottom: 128px;
    }
  }