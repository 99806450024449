/* CustomStyles.css */
.react-datepicker .react-datepicker__time-container {
  width: 65px;
  height: 65px;
}

.react-datepicker .react-datepicker__time-box {
  overflow-y: scroll;
  /* changed from 'auto' to 'scroll' */
  /* max-height: calc(195px + 0.18125rem); */
  font-size: 11px;

  margin: 0;
  padding: 0;
  top:0;
  height: 120px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 14px;
  padding: 0;
  white-space: nowrap;
}

/* 
.react-datepicker .react-datepicker__time-list-item {
  height: 150px;
  padding: 3px;
} */

/* Add these rules to keep the scrollbar visible */
.react-datepicker .react-datepicker__time-list::-webkit-scrollbar {
  /* width: 12px; */
  /* height: 65px; */
  /* background-color: #F5F5F5; */
  border-radius: 5px;
}


.react-datepicker .react-datepicker__month-container {
  width: 141px;
  /* height: 650px; */
  /* Adjust this value to change the calendar width */
}


.react-datepicker .react-datepicker__current-month,
.react-datepicker .react-datepicker-time__header,
.react-datepicker .react-datepicker-year-header {
  font-size: 12px;
}

.smaller-date-box {
  width: 55px;
  /* or whatever size you want */
}

.medium-date-box {
  width: 70px;
  /* or whatever size you want */
}

.regular-date-box {
  width: 120px;
  /* or whatever size you want */
}

.react-datepicker .react-datepicker__navigation--previous {
  left: 0px;
  top: 6px;
}

.react-datepicker .react-datepicker__navigation--next {
  left:109px;
  top: 6px;
}

.react-datepicker-popper {
  z-index: 9999
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 0.9rem;
  line-height: 1.0rem;
  text-align: center;
  margin: 0.13rem;
}

