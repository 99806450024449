  /* Popup container adjustments */
  .dailyforecastpopup {
    position: fixed;
    transform: scale(0.8);
    top: -1%;
    left: -6%;
    right: 0;
    margin: auto;
    width: 110%;
    max-height: 103%;
    overflow-y: auto;
    background: rgba(30, 30, 30, 0.9);
    border-radius: 8px;
    z-index: 3000;
    padding: 10px;
  }

  .daily-forecast-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* Default: 8 columns on large screens */
    gap: 10px;
    box-sizing: border-box;
  }

  .daily-forecast-card {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #1e1e1e;
    border-radius: 8px;
    padding: 6px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    font-size: 12px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* take all available vertical space */
  }

  .iconContainer {
    margin-bottom: 0;
  }

  .condition-icon {
    display: block;
    width: 70px;
    height: auto;
    margin: 0 auto;
  }

  .temperatureContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 26px;
  }

  .temps-row {
    display: flex;
    align-items: baseline;
    justify-content: center;
    /* center horizontally */
    gap: 4px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }

  .temp-separator {
    margin: 0 3px;
  }

  .parameter-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    width: 80%;
    max-width: 160px;
    margin-bottom: 2px;
  }

  .precip-parameter {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 4px;
    border-radius: 4px;
  }

  .parameter-row:last-of-type {
    margin-bottom: 16px;
  }

  .parameter-label {
    font-weight: 600;
  }

  .hourly-link {
    background: none;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    padding: 3px 9px;
    font-size: 12px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 8px;
  }

  .modelrun {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: normal;
    color: #ffffff;
  }

  .ddate {
    font-weight: bold;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 14px;
  }

  .close-button-daily {
    position: absolute;
    top: 0%;
    left: 1px;
    font-size: .9em;
    font-weight: bold;
    color: black;
    outline: auto;
    background-color: white;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }

  /* .close-button:hover {
  color: red;
} */

  /* Mobile-friendly adjustments */
  @media screen and (max-width: 600px) {

    .daily-forecast-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two columns */
      gap: 15px;
      padding: 15px;
      width: 95vw; /* Occupies most of the screen width */
      max-width: 1200px;
      margin: 0 auto; /* Centers the container */
    }

    .dailyforecastpopup {
      /* Make sure it fits narrower devices */
      left: 0;
      width: 100%;
    }
  }