.legend-container {
  position: relative;
  bottom:30px;
  right: 20px;
  z-index: 1000;
  background-color: transparent;
  border-radius: 8px;
}

.dark-input {
    border-color: white;
    font-size: 15px;
  }
  

.dynamic-width {
  color: rgb(255, 255, 255);
  text-align: center;
  background-color: #0a162f;
  border-radius: 15px;
  padding: 2px;
  width:58px;
  font-size: 15px;
  font-weight: bold;
  margin-left: -4px;
  display: flex;
  flex-direction: column; 
}

.min-position {
  position: absolute;
  left: -62px;
  bottom: 18px;

}
.max-position {
  position: absolute;
  right: -67px;
  bottom: 18px;

}

.lock-button {
    position: absolute;
    left: -130px;
    bottom: 17px;
  }

  /* Hide increment and decrement arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}