.opacity-slider-container {
    text-align: center;
    /* Center align the container's content */
    margin-top: -7px;
}

.opacity-label {
    display: block;
    /* Make label a block element to occupy its own line */
    margin-bottom: 2px;
    /* Space between label and slider */
}