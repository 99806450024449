.forecastpopup {
  transform: scale(0.7);
  position: fixed;
  bottom: -3%;
  left: -15%;
  right: 0;
  margin: auto;
  width: 130%;
  height: 288px;
  background: transparent;
  z-index: 3000;
}

.descriptioncontainer {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 500;
  color: aliceblue;
  background: linear-gradient(180deg, rgb(76, 4, 4) 0%, rgba(0, 212, 255, 1) 100%);
  height: 100%;
  width: 144px;
}

.forecastcontainer {
  left: 144px;
  position: absolute;
  z-index: 500;
  color: aliceblue;
  height: 100%;
  max-width: calc(100% - 144px);
  overflow-x: auto;
  /* enables horizontal scrolling */
  overflow-y: hidden;
  white-space: nowrap;
}


.forecastcard {
  width: 70px;
  height: 290px;
  background: linear-gradient(180deg, rgb(0, 38, 255) 0%, rgb(0, 234, 255) 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin-left: 0px;
  scroll-snap-align: center;
}

.fdate {
  margin-top: 52px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.time {
  font-size: 14px;
  margin-top: 6px;
  color: white;
  margin-left: 20px;
  font-weight: bold;
}

.tempparam {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  top: 90px;
  margin-top: 7px;
  color: white;
  margin-left: 25px;
  z-index: 3003;
}

.parameters {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  top: 37px;
  margin-top: 7px;
  color: white;
  text-align: center;
  z-index: 3003;
}

.descrip_param {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  top: 14px;
  margin-top: 7px;
  color: white;
  text-align: center;
  z-index: 3003;
}

.image {
  position: absolute;
  margin-top: 6px;
  margin-left: 5px;
  height: 50px;
  width: 50px;
}

.description {
  font-size: 14px;
  color: white;
  margin-left: 20px;
  font-weight: bold;
}

.forecastbutton {
  font-size: 14px;
  color: 'black';
  margin-left: 15px;
}

.close-button-hourly {
  position: absolute;
  top: 0%;
  left: 1px;
  font-size: .9em;
  font-weight: bold;
  color: black;
  outline: auto;
  background-color: white;
  border: none;
  cursor: pointer;
}


.nextday {
  position: absolute;
  font-size: 12px;
  color: 'black';
  bottom: 0%;
  left: 115px;
  height: 30px;
  z-index: 6000;
}

.prevday {
  position: absolute;
  height: 30px;
  font-size: 12px;
  color: 'black';
  bottom: 0%;
  left: 0px;
  z-index: 6000;
}

.dropdown_forecast_model {
  position: absolute;
  top: 25px;
  left: 50px;
  font-size: .9em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 6000;
}

.forecast_model_run {
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: transparent;
  top: 0px;
  right: 25px;
  font-size: .8em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 80px;
  z-index: 3001;
}

.temperatureContainerHours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  top: -28px;
  background-color: transparent;
}

.temperatureLine {
  position: relative;
  top: 67px;
  left: 0;
  right: 0;
  height: 25px;
  opacity: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.forecastDatePicker {
  bottom: 0%;
  left: 24px;
  position: absolute;
  z-index: 5000;
  font-size: .9em;
}

.no-data-message {
  color: black;
  /* Text in black */
  font-size: 18px;
  /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  /* Center the message */
  padding: 10px;
  position: relative;
  display: inline-block;

  /* White shadow around the text to create a mask effect */
  text-shadow:
    1px 1px 2px white,
    /* Horizontal and vertical shadow */
    -1px -1px 2px white,
    /* Top left */
    -1px 1px 2px white,
    /* Bottom left */
    1px -1px 2px white;
  /* Top right */
}

/* Mobile-friendly adjustments */
@media screen and (max-width: 600px) {

  .forecastpopup {
    position: fixed;
    transform: none;
    bottom: 12%;
    left: 2%;
    width: 95%;
    height: 288px;
    background: transparent;
    z-index: 3000;
  }

  .descriptioncontainer {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 500;
    color: aliceblue;
    background: linear-gradient(180deg, rgb(76, 4, 4) 0%, rgba(0, 212, 255, 1) 100%);
    height: 100%;
    width: 144px;
  }
  .forecastcontainer {
    position: absolute;
    z-index: 500;
    color: aliceblue;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}