/* ForecastDatePickerStyles.css */
.forecastWindow .react-datepicker__navigation--previous {
  left: -10px;
  top: 7px;
}

.forecastWindow .react-datepicker__navigation--next {
  left: 120px;
  top: 7px;
}

.forecastWindow .react-datepicker__input-container input {
  width: 93px;
  height: 29px;
  left: 0px;
}

.forecastWindow .react-datepicker .react-datepicker__current-month,
.forecastWindow .react-datepicker .react-datepicker-year-header {
  font-size: 14px;

}
