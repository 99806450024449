/* About.css */
body {
    background: linear-gradient(180deg, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 100%) !important; /* Dark background for the entire page */
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

.terms {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333; /* Changed to a dark grey for better readability */
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* A very light grey for the background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
  }
  
  .about h1, .about h2 {
    color: #0056b3 !important; /* Adjusted to a darker shade of blue for headings */
    font-size: 1.5em !important;
  }
  
  .about p, .about ul {
    
    line-height: 1.6; /* Spacing for readability */
  }
  
  .about ul {
    list-style: inside square; /* Styling the lists */
  }
  
  .about ul ul {
    list-style-type: circle; /* Different style for nested lists */
  }
  
  .about a {
    color: #0056b3; /* Matching links to heading colors, adjusted to darker blue */
    text-decoration: none; /* Optional: removes underline from links */
  }
  
  .about a:hover {
    text-decoration: underline; /* Adds underline on hover for links */
  }
  
  .aboutimage1 {
    display: block;
    margin: 20px auto;
    max-width: 100px;
    height: auto;
  }
  
  .terms-link {
    display: inline-block;
    background-color: #d6d4d4; /* Slightly darker grey for contrast */
    color: #0056b3;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center;
  }
  
  .terms-link h1 {
    margin: 0; /* Removes margin from h1 inside the link */
  }
  
  .contact-email {
    font-weight: bold;
  }
  